<template>
  <div class="wrap">
    <div v-if="!['A', 'E', 'I'].includes(productType)" class="content">
      <div
        class="teeth"
        @click="teethClick(index, item, 'one')"
        v-for="(item, index) in teethOne"
        :key="index"
      >
        <span class="tooth-fdi" :class="{ main_theme_color_333: isOldCase }">{{
          item.number
        }}</span>
        <el-popover
          :disabled="readOnly || !selectTeethFn(item.number, selectOne)"
          placement="bottom"
          @after-leave="popoverHide(index, 'one', item)"
          ref="onePopover"
          trigger="click"
        >
          <div class="flex-y-c">
            <el-select
              style="width: 2.2rem"
              size="medium"
              @change="(val) => selectChange(val, item, 'teethOne', selectOne)"
              v-model="item.spacingType"
              :placeholder="$t('cases.createDiagnosis.controlgap')"
            >
              <el-option
                v-for="it in options"
                :key="it.value"
                :label="it.label"
                :value="it.value"
              >
              </el-option>
            </el-select>
            <div
              @click="selectChange('maintain', item, 'teethOne', selectOne)"
              :class="{ active_btn: item['maintain'] }"
              class="ml20 wc_btn"
            >
              {{ $t("cases.createDiagnosis.wcjx") }}
            </div>
          </div>
          <div class="interval_input">
            <div
              class="posr"
              v-show="item.spacingType === '1' || item.spacingType === '2'"
            >
              <el-input
                class="input_width"
                :value="item.width"
                @change="disposeNumber({ key: item, value: arguments[0] })"
                @input="disposeNumber({ key: item, value: arguments[0] })"
                v-model="item.width"
                :placeholder="
                  item.spacingType == '1'
                    ? $t('cases.createDiagnosis.kdjxz')
                    : $t('cases.createDiagnosis.sxjxz')
                "
              ></el-input>
              <span class="input_mm">mm</span>
            </div>
          </div>
          <div
            @click="resetNumber(item, 'teethOne', index)"
            v-if="index !== teethOne.length - 1"
            class="com_img"
            :class="{
              [{
                irdt: 'purple_kd',
                admin: 'blue_kd',
                clinic: 'blue_kd',
              }[queryPort] || 'big_kd']: item['spacingType'] === '1',
              [{
                irdt: 'purple_sx',
                admin: 'blue_sx',
                clinic: 'blue_sx',
              }[queryPort] || 'big_sx']: item['spacingType'] === '2',
              [{
                irdt: 'purple_gb',
                admin: 'blue_gb',
                clinic: 'blue_gb',
              }[queryPort] || 'big_gb']: item['spacingType'] === '3',
              [{
                irdt: 'purple_wc',
                admin: 'blue_wc',
                clinic: 'blue_wc',
              }[queryPort] || 'big_wc']: item['maintain'],
              'curp hvr-bd': selectTeethFn(item.number, selectOne),
            }"
            slot="reference"
          ></div>
        </el-popover>
      </div>
    </div>
    <div v-if="!productType || ['B', 'G', 'H', 'I'].includes(productType)" class="content">
      <div
        class="teeth"
        @click="teethClick(index, item, 'two')"
        v-for="(item, index) in teethTow"
        :key="index"
      >
        <span class="tooth-fdi" :class="{ main_theme_color_333: isOldCase }">{{
          item.number
        }}</span>
        <el-popover
          :disabled="readOnly || !selectTeethFn(item.number, selectTow)"
          placement="bottom"
          @after-leave="popoverHide(index, 'two', item)"
          ref="twoPopover"
          trigger="click"
        >
          <div class="flex-y-c">
            <el-select
              style="width: 2.2rem"
              size="medium"
              @change="(val) => selectChange(val, item, 'teethTow', selectTow)"
              v-model="item.spacingType"
              :placeholder="$t('cases.createDiagnosis.controlgap')"
            >
              <el-option
                v-for="it in options"
                :key="it.value"
                :label="it.label"
                :value="it.value"
              >
              </el-option>
            </el-select>
            <div
              @click="selectChange('maintain', item, 'teethTow', selectTow)"
              :class="{ active_btn: item['maintain'] }"
              class="ml20 wc_btn"
            >
              {{ $t("cases.createDiagnosis.wcjx") }}
            </div>
          </div>
          <div class="interval_input">
            <div
              class="posr"
              v-show="item.spacingType === '1' || item.spacingType === '2'"
            >
              <el-input
                class="input_width"
                :value="item.width"
                @change="disposeNumber({ key: item, value: arguments[0] })"
                @input="disposeNumber({ key: item, value: arguments[0] })"
                :placeholder="
                  item.spacingType == '1'
                    ? $t('cases.createDiagnosis.kdjxz')
                    : $t('cases.createDiagnosis.sxjxz')
                "
              ></el-input>
              <span class="input_mm">mm</span>
            </div>
          </div>
          <div
            @click="resetNumber(item, 'teethTow', index)"
            v-if="index !== teethTow.length - 1"
            class="com_img"
            :class="{
              [{
                irdt: 'purple_kd',
                admin: 'blue_kd',
                clinic: 'blue_kd',
              }[queryPort] || 'big_kd']: item['spacingType'] === '1',
              [{
                irdt: 'purple_sx',
                admin: 'blue_sx',
                clinic: 'blue_sx',
              }[queryPort] || 'big_sx']: item['spacingType'] === '2',
              [{
                irdt: 'purple_wc',
                admin: 'blue_wc',
                clinic: 'blue_wc',
              }[queryPort] || 'big_wc']: item['maintain'],
              [{
                irdt: 'purple_gb',
                admin: 'blue_gb',
                clinic: 'blue_gb',
              }[queryPort] || 'big_gb']: item['spacingType'] === '3',
              'curp hvr-bd': selectTeethFn(item.number, selectTow),
            }"
            slot="reference"
          ></div>
        </el-popover>
      </div>
    </div>
    <div v-if="!productType ||  ['B', 'G', 'H', 'I'].includes(productType)" class="content">
      <div
        class="teeth"
        @click="teethClick(index, item, 'three')"
        v-for="(item, index) in teethThree"
        :key="index"
      >
        <span class="tooth-fdi" :class="{ main_theme_color_333: isOldCase }">{{
          item.number
        }}</span>
        <el-popover
          ref="threePopover"
          :disabled="readOnly || !selectTeethFn(item.number, selectThree)"
          placement="bottom"
          @after-leave="popoverHide(index, 'three', item)"
          trigger="click"
        >
          <div class="flex-y-c">
            <el-select
              style="width: 2.2rem"
              size="medium"
              @change="
                (val) => selectChange(val, item, 'teethThree', selectThree)
              "
              v-model="item.spacingType"
              :placeholder="$t('cases.createDiagnosis.controlgap')"
            >
              <el-option
                v-for="it in options"
                :key="it.value"
                :label="it.label"
                :value="it.value"
              >
              </el-option>
            </el-select>
            <div
              @click="selectChange('maintain', item, 'teethThree', selectThree)"
              :class="{ active_btn: item['maintain'] }"
              class="ml20 wc_btn"
            >
              {{ $t("cases.createDiagnosis.wcjx") }}
            </div>
          </div>
          <div class="interval_input">
            <div
              class="posr"
              v-show="item.spacingType === '1' || item.spacingType === '2'"
            >
              <el-input
                class="input_width"
                :value="item.width"
                @change="disposeNumber({ key: item, value: arguments[0] })"
                @input="disposeNumber({ key: item, value: arguments[0] })"
                :placeholder="
                  item.spacingType === '1'
                    ? $t('cases.createDiagnosis.kdjxz')
                    : $t('cases.createDiagnosis.sxjxz')
                "
              ></el-input>
              <span class="input_mm">mm</span>
            </div>
          </div>
          <div
            @click="resetNumber(item, 'teethThree', index)"
            v-if="index !== teethThree.length - 1"
            class="com_img"
            :class="{
              [{
                irdt: 'purple_kd',
                admin: 'blue_kd',
                clinic: 'blue_kd',
              }[queryPort] || 'big_kd']: item['spacingType'] === '1',
              [{
                irdt: 'purple_sx',
                admin: 'blue_sx',
                clinic: 'blue_sx',
              }[queryPort] || 'big_sx']: item['spacingType'] === '2',
              [{
                irdt: 'purple_wc',
                admin: 'blue_wc',
                clinic: 'blue_wc',
              }[queryPort] || 'big_wc']: item['maintain'],
              [{
                irdt: 'purple_gb',
                admin: 'blue_gb',
                clinic: 'blue_gb',
              }[queryPort] || 'big_gb']: item['spacingType'] === '3',
              'curp hvr-bd': selectTeethFn(item.number, selectThree),
            }"
            slot="reference"
          ></div>
        </el-popover>
      </div>
    </div>
    <div v-if="!['A', 'E', 'I'].includes(productType)" class="content">
      <div
        class="teeth"
        @click="teethClick(index, item, 'four')"
        v-for="(item, index) in teethFour"
        :key="index"
      >
        <span class="tooth-fdi" :class="{ main_theme_color_333: isOldCase }">{{
          item.number
        }}</span>
        <el-popover
          :disabled="readOnly || !selectTeethFn(item.number, selectFour)"
          placement="bottom"
          @after-leave="popoverHide(index, 'four', item)"
          ref="fourPopover"
          trigger="click"
        >
          <div class="flex-y-c">
            <el-select
              style="width: 2.2rem"
              size="medium"
              @change="
                (val) => selectChange(val, item, 'teethFour', selectFour)
              "
              v-model="item.spacingType"
              :placeholder="$t('cases.createDiagnosis.controlgap')"
            >
              <el-option
                v-for="it in options"
                :key="it.value"
                :label="it.label"
                :value="it.value"
              >
              </el-option>
            </el-select>
            <div
              @click="selectChange('maintain', item, 'teethFour', selectFour)"
              :class="{ active_btn: item['maintain'] }"
              class="ml20 wc_btn"
            >
              {{ $t("cases.createDiagnosis.wcjx") }}
            </div>
          </div>
          <div class="interval_input">
            <div
              class="posr"
              v-show="item.spacingType === '1' || item.spacingType === '2'"
            >
              <el-input
                class="input_width"
                :value="item.width"
                @change="disposeNumber({ key: item, value: arguments[0] })"
                @input="disposeNumber({ key: item, value: arguments[0] })"
                :placeholder="
                  item.spacingType == '1'
                    ? $t('cases.createDiagnosis.kdjxz')
                    : $t('cases.createDiagnosis.sxjxz')
                "
              ></el-input>
              <span class="input_mm">mm</span>
            </div>
          </div>
          <div
            @click="resetNumber(item, 'teethFour', index)"
            v-if="index !== teethFour.length - 1"
            class="com_img"
            :class="{
              [{
                irdt: 'purple_kd',
                admin: 'blue_kd',
                clinic: 'blue_kd',
              }[queryPort] || 'big_kd']: item['spacingType'] === '1',
              [{
                irdt: 'purple_sx',
                admin: 'blue_sx',
                clinic: 'blue_sx',
              }[queryPort] || 'big_sx']: item['spacingType'] === '2',
              [{
                irdt: 'purple_wc',
                admin: 'blue_wc',
                clinic: 'blue_wc',
              }[queryPort] || 'big_wc']: item['maintain'],
              [{
                irdt: 'purple_gb',
                admin: 'blue_gb',
                clinic: 'blue_gb',
              }[queryPort] || 'big_gb']: item['spacingType'] === '3',
              'curp hvr-bd': selectTeethFn(item.number, selectFour),
            }"
            slot="reference"
          ></div>
        </el-popover>
      </div>
    </div>
    <div
      :class="{ detail_old: isOldCase }"
      class="detail"
      v-if="find(totalTeeth, (item) => item.spacingType || item.maintain)"
    >
      <template v-for="(item, index) in totalTeeth">
        <p
          class="fz16rem mb1rem"
          :key="index"
          v-if="item.spacingType || item.maintain"
        >
          {{ item.number }},
          {{ item.nextNumber }}
          {{
            isShowText[item.spacingType] ||
            (item.maintain ? $t("cases.createDiagnosis.maintain") : "")
          }}{{ $t("cases.createDiagnosis.spacing") }}
          <span v-if="item.width">{{ item.width }}mm</span>
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import {
  extend,
  map,
  pick,
  filter,
  each,
  pickBy,
  uniq,
  isArray,
  isNumber,
  find,
} from "lodash";
import { toNumber } from "../../../common/js/Fn";

export default {
  data() {
    return {
      options: [
        {
          label: this.$t("cases.createDiagnosis.kdjx"),
          value: "1",
        },
        {
          label: this.$t("cases.createDiagnosis.sxjx"),
          value: "2",
        },
        {
          label: this.$t("cases.createDiagnosis.gbjx"),
          value: "3",
        },
      ],
      teethOne: [
        {
          number: 55,
          spacingType: "",
        },
        {
          number: 54,
          spacingType: "",
        },
        {
          number: 53,
          spacingType: "",
        },
        {
          number: 52,
          spacingType: "",
        },
        {
          number: 51,
          spacingType: "",
        },
        {
          number: 61,
          spacingType: "",
        },
        {
          number: 62,
          spacingType: "",
        },
        {
          number: 63,
          spacingType: "",
        },
        {
          number: 64,
          spacingType: "",
        },
        {
          number: 65,
          spacingType: "",
        },
      ],
      teethTow: [
        {
          number: 18,
          spacingType: "",
        },
        {
          number: 17,
          spacingType: "",
        },
        {
          number: 16,
          spacingType: "",
        },
        {
          number: 15,
          spacingType: "",
        },
        {
          number: 14,
          spacingType: "",
        },
        {
          number: 13,
          spacingType: "",
        },
        {
          number: 12,
          spacingType: "",
        },
        {
          number: 11,
          spacingType: "",
        },
        {
          number: 21,
          spacingType: "",
        },
        {
          number: 22,
          spacingType: "",
        },
        {
          number: 23,
          spacingType: "",
        },
        {
          number: 24,
          spacingType: "",
        },
        {
          number: 25,
          spacingType: "",
        },
        {
          number: 26,
          spacingType: "",
        },
        {
          number: 27,
          spacingType: "",
        },
        {
          number: 28,
          spacingType: "",
        },
      ],
      teethThree: [
        {
          number: 48,
          spacingType: "",
        },
        {
          number: 47,
          spacingType: "",
        },
        {
          number: 46,
          spacingType: "",
        },
        {
          number: 45,
          spacingType: "",
        },
        {
          number: 44,
          spacingType: "",
        },
        {
          number: 43,
          spacingType: "",
        },
        {
          number: 42,
          spacingType: "",
        },
        {
          number: 41,
          spacingType: "",
        },
        {
          number: 31,
          spacingType: "",
        },
        {
          number: 32,
          spacingType: "",
        },
        {
          number: 33,
          spacingType: "",
        },
        {
          number: 34,
          spacingType: "",
        },
        {
          number: 35,
          spacingType: "",
        },
        {
          number: 36,
          spacingType: "",
        },
        {
          number: 37,
          spacingType: "",
        },
        {
          number: 38,
          spacingType: "",
        },
      ],
      teethFour: [
        {
          number: 85,
          spacingType: "",
        },
        {
          number: 84,
          spacingType: "",
        },
        {
          number: 83,
          spacingType: "",
        },
        {
          number: 82,
          spacingType: "",
        },
        {
          number: 81,
          spacingType: "",
        },
        {
          number: 71,
          spacingType: "",
        },
        {
          number: 72,
          spacingType: "",
        },
        {
          number: 73,
          spacingType: "",
        },
        {
          number: 74,
          spacingType: "",
        },
        {
          number: 75,
          spacingType: "",
        },
      ],
      isShowText: {
        1: this.$t("cases.createDiagnosis.expansion"),
        2: this.$t("cases.createDiagnosis.retraction"),
        3: this.$t("cases.createDiagnosis.closure"),
      },
      teethKey: ["teethOne", "teethTow", "teethThree", "teethFour"],
      teethList: [],
      setList: false,
    };
  },
  props: {
    readOnly: {
      type: Boolean,
    },
    commonTeeth: {
      type: Array,
    },
    uploadIntervalList: {
      type: Array,
    },
    productType: {
      type: String,
    },
    isOldCase: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectList() {
      return map(
        filter(this.commonTeeth, (item) => item.number && !item.deficiency),
        (it) => it.number
      );
    },
    missingToothList() {
      return filter(this.commonTeeth, (item) => item.deficiency);
    },
    selectOne() {
      let list = filter(this.selectList, (item) =>
        [55, 54, 53, 52, 51, 61, 62, 63, 64, 65].includes(item)
      );
      let leftTooth = filter(list, (item) => item >= 51 && item <= 55).sort(
        (a, b) => b - a
      );
      let rightTooth = filter(list, (item) => item >= 61 && item <= 65).sort(
        (a, b) => a - b
      );
      return [...leftTooth, ...rightTooth];
    },
    selectTow() {
      let list = filter(this.selectList, (item) =>
        [
          18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
        ].includes(item)
      );
      let leftTooth = filter(list, (item) => item >= 11 && item <= 18).sort(
        (a, b) => b - a
      );
      let rightTooth = filter(list, (item) => item >= 21 && item <= 28).sort(
        (a, b) => a - b
      );
      return [...leftTooth, ...rightTooth];
    },
    selectThree() {
      let list = filter(this.selectList, (item) =>
        [
          48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
        ].includes(item)
      );
      let leftTooth = filter(list, (item) => item >= 41 && item <= 48).sort(
        (a, b) => b - a
      );
      let rightTooth = filter(list, (item) => item >= 31 && item <= 38).sort(
        (a, b) => a - b
      );
      return [...leftTooth, ...rightTooth];
    },
    selectFour() {
      let list = filter(this.selectList, (item) =>
        [85, 84, 83, 82, 81, 71, 72, 73, 74, 75].includes(item)
      );
      let leftTooth = filter(list, (item) => item >= 81 && item <= 85).sort(
        (a, b) => b - a
      );
      let rightTooth = filter(list, (item) => item >= 71 && item <= 75).sort(
        (a, b) => a - b
      );
      return [...leftTooth, ...rightTooth];
    },
    totalTeeth() {
      return [
        ...this.teethOne,
        ...this.teethTow,
        ...this.teethThree,
        ...this.teethFour,
      ];
    },
    commonTeethNew() {
      return this.commonTeeth
        ? JSON.parse(JSON.stringify(this.commonTeeth))
        : [];
    },
    queryPort() {
      return;
    },
  },
  watch: {
    commonTeethNew: {
      handler(newValue, old) {
        if (
          isArray(newValue) &&
          newValue.length &&
          isArray(old) &&
          old.length &&
          newValue.length < old.length
        ) {
          let list = map(newValue, (it) => it.number);
          let number;
          each(old, (item) => {
            if (!list.includes(item.number)) {
              number = item.number;
            }
          });
          each(this.teethKey, (item) => {
            this[item] = map(this[item], (it) => {
              if (
                (it.nextNumber && it.nextNumber === number) ||
                it.number === number
              ) {
                return pickBy(
                  extend({}, it, {
                    nextNumber: "",
                    [it.spacingType]: "",
                    spacingType: "",
                  })
                );
              }
              return it;
            });
          });
        } else {
          let number, isClickDeficiency;
          let newDeficiencyList = map(
            filter(newValue, (it) => it.deficiency),
            (a) => a.number
          );
          let oldDeficiencyList = map(
            filter(old, (it) => it.deficiency),
            (a) => a.number
          );
          isClickDeficiency =
            newDeficiencyList.length > oldDeficiencyList.length;
          if (isClickDeficiency) {
            each(newDeficiencyList, (item) => {
              if (!oldDeficiencyList.includes(item)) {
                number = item;
              }
            });
          }
          each(this.teethKey, (item) => {
            this[item] = map(this[item], (it) => {
              if (
                (it.nextNumber && it.nextNumber === number) ||
                it.number === number
              ) {
                return pickBy(
                  extend({}, it, {
                    nextNumber: "",
                    [it.spacingType]: "",
                    spacingType: "",
                  })
                );
              }
              return it;
            });
          });

          let list = {
            0: "selectOne",
            1: "selectTow",
            2: "selectThree",
            3: "selectFour",
          };
          each(this.teethKey, (item, index) => {
            this[item] = map(this[item], (it) => {
              if (it.nextNumber) {
                return {
                  ...it,
                  nextNumber: this.findAdjoinTooth(
                    it.number,
                    this[list[index]]
                  ),
                };
              }
              return it;
            });
          });
        }
      },
      deep: true,
      immediate: true,
    },
    uploadIntervalList: {
      handler(val) {
        let list = ["teethOne", "teethTow", "teethThree", "teethFour"];
        if (val.length) {
          if (this.setList) {
            this.teethList = val;
            this.setList = false;
          }
          each(list, (listKey) => {
            this[listKey] = map(this[listKey], (item) => {
              let obj = item;
              each(val, (it) => {
                if (item.number === it.number) {
                  return (obj = extend(
                    {},
                    item,
                    it,
                    pickBy(
                      { width: it.spacingType === "3" ? null : "" },
                      (it) => it !== ""
                    ),
                    pickBy(
                      { maintain: it.spacingType === "4" ? 0.01 : "" },
                      (it) => it !== ""
                    )
                  ));
                }
              });
              return obj;
            });
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    find,
    popoverHide(index, type, item) {
      if (item && item.spacingType === "" && !item.maintain) {
        this.teethList = filter(
          this.teethList,
          (it) => it.number !== item.number
        );
        this.$refs[`${type}Popover`][index].doClose();
      }
    },
    teethClick(index, item, type) {
      // doShow()   doClose()
      if (map(this.teethList, (it) => it.number).includes(item.number)) {
        this.$refs[`${type}Popover`][index].doClose();
        this.teethList = filter(
          this.teethList,
          (it) => it.number !== item.number
        );
      } else {
        this.teethList.push(item);
        this.$refs[`${type}Popover`][index].doShow();
        //默认打开注释
        // let {number, maintain, spacingType} = item;
        // if(this.selectList.includes(number)){
        //   if(!maintain && !spacingType){
        //     let types = {
        //       one: 'One',
        //       two: 'Tow',
        //       three: 'Three',
        //       four: 'Four'
        //     }[type];
        //     this[`teeth${types}`] = map(this[`teeth${types}`], it => {
        //       if(number === it.number){
        //         return extend({}, it, {spacingType: '1', width: '', nextNumber: this.findAdjoinTooth(number,this[`select${types}`])})
        //       }
        //       return it
        //     })
        //   }
        // }
      }
    },
    // 输入框只能输入数字
    disposeNumber({ key, value }) {
      if (value === "") return (key.width = "");
      key.width =
        isNumber(toNumber(value)) && toNumber(value) <= 25
          ? toNumber(value)
          : "";
    },
    // 重置数字
    resetNumber(item, list, index) {
      if (this.readOnly) return;
      if (item.spacingType) {
        this[list][index].spacingType = "";
        this[list][index].width = "";
      }
      if (item.maintain) {
        this[list][index].maintain = "";
      }
    },
    selectTeethFn(number, list) {
      return list.indexOf(number) !== -1 && this.findAdjoinTooth(number, list);
    },
    selectChange(val, item, listType, selectList) {
      if (this.readOnly) return;
      this[listType] = map(this[listType], (it) => {
        if (item.number === it.number) {
          if (val === "maintain") {
            return pickBy({
              number: item.number,
              spacingType: "",
              nextNumber: item.maintain
                ? ""
                : this.findAdjoinTooth(item.number, selectList),
              maintain: item.maintain ? null : true,
            });
          }
          return pick(
            {
              ...it,
              width:
                val === "3" || (val === "maintain" && item[val]) ? null : "",
              spacingType: it.spacingType,
              nextNumber: this.findAdjoinTooth(item.number, selectList),
            },
            ["spacingType", "number", "width", "nextNumber"]
          );
        } else {
          return it;
        }
      });
      this.$emit(
        "updateInterval",
        filter(
          [
            ...this.teethOne,
            ...this.teethTow,
            ...this.teethThree,
            ...this.teethFour,
          ],
          (item) => item.maintain || item.spacingType
        )
      );
    },
    findAdjoinTooth(number, list) {
      let index = list.indexOf(number);
      return list[index + 1];
    },
    clearTeeth() {
      each(["teethOne", "teethTow", "teethThree", "teethFour"], (list) => {
        this[list] = map(this[list], (item) => {
          return {
            number: item.number,
            spacingType: "",
          };
        });
      });
    },
  },
  created() {
    this.setList = true;
  },
};
</script>

<style lang="scss" scoped>
.input_mm {
  position: absolute;
  right: 1.5rem;
  top: 0.3rem;
}
.wrap {
  width: 100%;
  .content {
    display: flex;
    justify-content: center;
    margin-bottom: 0.2rem;
    .teeth {
      width: 0.5rem;
      display: flex;
      align-items: center;
      margin-left: 0.1rem;

      .tooth-fdi {
        font-size: 14px;
        white-space: nowrap;
      }

      .interval_input {
        display: flex;
      }
      span {
        font-size: 0.16rem;
      }
      div {
        margin-left: 0.1rem;
        width: 0.25rem;
        height: 0.25rem;
        border: 0.01rem solid #898989;
        text-align: center;
        line-height: 0.4rem;
        border-radius: 0.05rem;
      }
    }
  }
  .detail {
    padding: 0.2rem;
    width: 100%;
    background-color: #58595a;
    margin: 0 auto;
    border-radius: 4px;
  }
  .detail_old {
    color: $main_theme_color_333;
    background: #f4f4f4;
  }
}
.input_width {
  margin-top: 0.2rem;
  width: 2.2rem;
}
.wc_btn {
  width: 1.2rem;
  height: 0.35rem;
  background-color: #fff;
  color: $main_theme_color_333;
  border: 0.01rem solid $main_theme_color_bbb;
  text-align: center;
  line-height: 0.35rem;
  cursor: pointer;
}
.active_btn {
  background-color: #FCC80E !important;
  color: white;
  border: 0.01rem solid #FCC80E;
}

.big_kd {
  background-image: url("./img/kd.png");
  border: none !important;
}
.blue_kd {
  background-image: url("./img/blue_kd.png");
  border: none !important;
}
.purple_kd {
  background-image: url("./img/purple_kd.png");
  border: none !important;
}

.big_sx {
  background-image: url("./img/sx.png");
  border: none !important;
}
.blue_sx {
  background-image: url("./img/blue_sx.png");
  border: none !important;
}
.purple_sx {
  background-image: url("./img/purple_sx.png");
  border: none !important;
}

.big_wc {
  background-image: url("./img/wc.png");
  border: none !important;
}
.blue_wc {
  background-image: url("./img/blue_wc.png");
  border: none !important;
}
.purple_wc {
  background-image: url("./img/purple_wc.png");
  border: none !important;
}

.big_gb {
  background-image: url("./img/gb.png");
  border: none !important;
}
.blue_gb {
  background-image: url("./img/blue_gb.png");
  border: none !important;
}
.purple_gb {
  background-image: url("./img/purple_gb.png");
  border: none !important;
}

.com_img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.hvr-bd{
  border: 0.01rem solid #FCC80E !important;
}
</style>